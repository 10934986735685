import React from 'react';

// components
import HeroSection from '../sections/Home/HeroSection';
import Solutions from '../sections/Home/Solutions';
import Integrate from '../sections/Home/Integrate';
import Experiences from '../sections/Home/Experiences';
import Pricing from '../sections/Home/Pricing';
import VerifyUi from '../sections/Home/VerifyUi';
import Demo from '../sections/Home/Demo';
import Seo from '../components/Seo';
import { ClientSideOnly } from '../components/utilities/ClientSideOnly';
import RootLayout from '../layouts/RootLayout.js';
import ContactUsForm from '../components/reusable/ContectUsForm/ContactUsForm.jsx';

export default function IndexPage() {
    return (
        <>
            <Seo />
            <RootLayout isHomePage>
                <HeroSection />
                <Solutions />
                <ClientSideOnly>
                    <Integrate />
                </ClientSideOnly>
                <Experiences />
                <VerifyUi />
                <Pricing />
                <Demo />
                <ContactUsForm isCloseBtnNeeded={false} />
            </RootLayout>
        </>
    );
}
